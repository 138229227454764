import React from 'react';
import { css, styled } from '@mui/material/styles';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import Link from '../../atoms/Link/Link';
import { APP_ROUTES } from '@/src/constants';
import { useTranslation } from '@/src/i18n';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

const SlidingCTAWrapper = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    bottom: 260px;
    margin-left: auto;
    display: flex;
    width: max-content;
    background-color: ${theme.palette.common.white};
    border-radius: ${theme.spacing(4, 0, 0, 4)};
    box-shadow: 0px ${theme.spacing(1)} ${theme.spacing(3)} rgba(0, 0, 0, 0.12);
    overflow-x: hidden; // Necessary to avoid global page overflow
    z-index: 10; // Necessary to sit above page content
    // We can't animate "width" but we can animate "max-width"
    transition: max-width 0.75s ease-out;
  `,
);

const IconWrapper = styled('div')(
  ({ theme }) => css`
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    margin: ${theme.spacing(0.5)};
    font-size: ${theme.spacing(2)};
    color: ${theme.palette.secondary.contrastText};
    border-radius: 50%;
    background-color: ${theme.palette.secondary.main};
  `,
);

const Label = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.main};
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-right: ${theme.spacing(2)};
  `,
);

const SlidingCTA = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    const slideOut = () => {
      setIsOpen(false);
    };

    const welcometimer = setTimeout(() => setIsOpen(true), 500);
    const timer = setTimeout(slideOut, 5000);

    window.addEventListener('scroll', slideOut);
    return () => {
      clearTimeout(welcometimer);
      clearTimeout(timer);

      window.removeEventListener('scroll', slideOut);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <SlidingCTAWrapper
        data-testid="mobile-sticky-cta"
        onClick={() => setIsOpen(prevState => !prevState)}
        sx={{ maxWidth: isOpen ? '100%' : '80px' }}
        className="cursor-pointer"
      >
        <IconWrapper className="relative flex size-13 items-center justify-center bg-secondary-hover">
          <FontAwesomeIcon
            name="video:solid"
            className="h-6 w-6 text-action-active"
          />
          <div className="absolute right-1 top-1 size-2 animate-blinker rounded-full bg-color-picker-radiant-green" />
        </IconWrapper>

        <Link href={APP_ROUTES.TLC.NO_RDV_SEARCH} passHref>
          <Label
            variant="h5"
            className="ml-5 font-body font-semibold text-content-primary"
          >
            {t('teleconsultation_without_appointment')}
          </Label>
        </Link>
      </SlidingCTAWrapper>
    </ClickAwayListener>
  );
};

export default SlidingCTA;
