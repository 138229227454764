/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

import {
  addressesActions,
  isEmpty,
  serialActions,
  suggestionsActions,
} from '@docavenue/core';
import { useCurrentUser } from '@docavenue/components';
import { useDispatch } from 'react-redux';
import dynamic from 'next/dynamic';

import { cn, Heading } from '@kiiwi/ui';
import { Trans, useTranslation } from '@/src/i18n';
import HomeTextBlock from '../../molecules/HomeTextBlock/HomeTextBlock';
import SlidingCTA from '../../molecules/SlidingCTA/SlidingCTA';
import { PatientUser } from '@/components/molecules/WithAuth/types';
import useResponsive from '@/src/hooks/useResponsive';
import HomePageCards from '@/components/molecules/HomePageCards/HomePageCards';
import { capitalizeFirstLetter } from '@/src/utils';
import SearchBar from '@/components/molecules/SearchBar/SearchBar';
import BannerInfoVaccin from '@/components/molecules/BannerInfoVaccin/BannerInfoVaccin';
import features, { READY } from '../../../src/features';
import MobileFakeSearch from '@/components/molecules/SearchBar/MobileFakeSearch';

const HomeConnected = dynamic(() => import('../HomeConnected/HomeConnected'));

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser<PatientUser>();
  const { firstName = '', lastName = '' } =
    currentUser?.userPatientInformation?.userPatientProfiles?.find(
      p => p.isDefault,
    ) || {};
  const inscriptionCompleted =
    (currentUser?.userPatientInformation.userPatientProfiles?.length ?? 0) > 0;

  const { isMobile, isTablet } = useResponsive();

  const isUserConnected = !isEmpty(currentUser);

  useEffect(() => {
    dispatch(
      serialActions.serial([
        () => suggestionsActions.reset(),
        () => addressesActions.setItems([]),
        () => addressesActions.setItem(null),
      ]),
    );
  }, []);

  return (
    <div>
      <div>
        <div className="relative bg-header-brand-surface">
          {!isMobile && !isTablet && (
            <>
              <img
                src="/static/images/home/header_left.svg"
                className={cn(
                  'absolute bottom-32 left-0',
                  isUserConnected && 'bottom-16',
                )}
                alt="BackgroundImageLeft"
              />
              <img
                src="/static/images/home/header_right.svg"
                className={cn(
                  'absolute bottom-16 right-0',
                  isUserConnected && 'bottom-8',
                )}
                alt="BackgroundImageLeft"
              />
            </>
          )}
          <div
            className={
              isMobile ? 'fixed right-0 z-10' : 'absolute right-0 top-14'
            }
          >
            <SlidingCTA />
          </div>
          <div
            className={cn(
              'relative flex flex-col pb-28 pt-16 text-content-primary lg:pb-52 lg:pt-44',
              isMobile ? 'px-3 text-start' : 'text-center',
              isUserConnected && 'pb-8 lg:py-32',
            )}
          >
            <Heading
              as="h1"
              className={cn(
                'text-title-2 font-black text-content-inverse sm:text-4xl',
                isUserConnected && 'text-title-1 sm:text-5xl',
              )}
            >
              {!isUserConnected ? (
                <Trans
                  i18nKey={
                    isMobile
                      ? 'homepage-title-not-authenticated-mobile'
                      : 'homepage-title-not-authenticated'
                  }
                  components={{ br: <br /> }}
                />
              ) : (
                <Trans
                  i18nKey={`homepage-title-authenticated${
                    isMobile ? '-mobile' : ''
                  }`}
                  values={{
                    name: `${capitalizeFirstLetter(
                      firstName,
                    )} ${capitalizeFirstLetter(lastName)}`,
                  }}
                  components={[<span className="block" key="0" />]}
                />
              )}
            </Heading>
            {isMobile ? (
              <MobileFakeSearch />
            ) : (
              <div className="search-bar mx-10 mt-14">
                {isUserConnected && (
                  <Heading
                    as="h4"
                    variant="subtitle-1"
                    className="mb-2 text-content-inverse"
                  >
                    {t('homepage-subtitle-authenticated')}
                  </Heading>
                )}
                <SearchBar />
              </div>
            )}
          </div>
        </div>
        {!isUserConnected && (
          <div className="bg-surface-white">
            <HomePageCards />
          </div>
        )}
        {features.vaccinationBanner === READY && (
          <BannerInfoVaccin variant="homepage" />
        )}
      </div>
      <div className="h-fit bg-white">
        {isUserConnected ? (
          <HomeConnected inscriptionCompleted={inscriptionCompleted} />
        ) : (
          <HomeTextBlock />
        )}
      </div>
    </div>
  );
};

export default Home;
