import cookies from 'js-cookie';
import config from './config';

export const READY = 'ready';
export const NOT_READY = 'notReady';

const features = {
  documentList_search: READY,
  documentList_filter: READY,
  documentCard_patientAdd: READY,
  miniTok_documentDragDrop: NOT_READY, // Disabled as request of Djamila for show menu with three options instead
  documentCardSeen: NOT_READY,
  responsiveTLC: READY,
  waitingList: READY,
  vaccinationBanner: NOT_READY, // Disabled as request of the product team
  egCountryPhone: config.get('ENVK8S') === 'production' ? NOT_READY : READY,
  disqus: READY,
  amcList: READY,
  createAmc: READY,
};

export const isFeatureKey = (key: string): key is keyof typeof features => {
  return typeof features[key] !== 'undefined';
};

export const isFeatureEnabled = (key: keyof typeof features): boolean => {
  const cookieValue = cookies.get(`ff_${key}`);
  switch (cookieValue) {
    case 'on':
      return true;
    case 'off':
      return false;
    default:
      return features[key] === READY;
  }
};

export default features;
