import React from 'react';
import { Box, Button } from '@mui/material';
import Link from '../../atoms/Link/Link';

import { useTranslation } from '@/src/i18n';
import BannerInfo from '../../atoms/BannerInfo/BannerInfo';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import { APP_ROUTES } from '@/src/constants';

type Props = {
  variant?: 'homepage' | 'search_page';
  className?: string;
};

const BannerInfoVaccin = ({ variant = 'search_page', ...rest }: Props) => {
  const { t } = useTranslation();

  return (
    <BannerInfo {...rest}>
      <img src="/static/images/picto_vaccin.svg" alt="Faites vous vacciner" />
      <div>
        <p>
          {variant === 'homepage' ? (
            t('find-appointment-homepage')
          ) : (
            <>
              <span>
                {t('discover-condition-vaccination')}{' '}
                <a
                  href="https://news.maiia.com/tout-savoir-sur-la-vaccination-covid-19/actualites/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-info"
                >
                  {t('here')}
                </a>
                .
              </span>{' '}
              {t('find-appointment')}
            </>
          )}
        </p>
        <Link href={APP_ROUTES.VACCINES_NEAR_ME} passHref>
          <Box mt={1} display="flex" justifyContent="center">
            <Button
              sx={theme => ({
                [theme.breakpoints.down('md')]: {
                  fontSize: theme.typography.body1.fontSize,
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: theme.typography.subtitle1.fontSize,
                },
              })}
              color="secondary"
              variant="contained"
              data-cy="btn-vaccine-near-me"
              onClick={() => {
                analyticsEvent({
                  category: 'TemporaryBanner',
                  action: 'ClickSearchVaccination',
                });
              }}
            >
              {t('entitled_location_vaccination_sites')}
            </Button>
          </Box>
        </Link>
      </div>
    </BannerInfo>
  );
};

export default BannerInfoVaccin;
