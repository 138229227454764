import { APP_ROUTES } from '@/src/constants';

const CARDS_DATA = [
  {
    content: 'homepage-card-tlc-content-2',
    media: 'tlc',
    ariaLabel: 'accessibility_card_tcl',
    buttonLabel: 'homepage-card-tlc-button-label',
    link: APP_ROUTES.TLC.NO_RDV_SEARCH,
    active: true,
  },
  {
    content: 'homepage-card-medications-content-2',
    media: 'medication',
    ariaLabel: 'accessibility_search_medication_files',
    buttonLabel: 'homepage-card-medications-button-label-2',
    link: APP_ROUTES.MEDICAMENTS.BASE_URL,
    active: true,
  },
  {
    content: 'homepage-card-vaccination-content',
    media: 'vaccination',
    ariaLabel: 'accessibility_search_vaccination_site',
    buttonLabel: 'homepage-card-vaccination-button-label',
    link: APP_ROUTES.VACCINES_NEAR_ME,
    active: false,
  },
  {
    content: 'homepage-card-data',
    media: 'data',
    ariaLabel: 'accessibility_data_site',
    buttonLabel: 'homepage-card-data-button-label',
    link: APP_ROUTES.POLICIES.PRIVACY,
    active: true,
  },
];
export default CARDS_DATA;
