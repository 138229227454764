import clsx from 'clsx';
import React from 'react';

type BannerInfoProps = {
  className?: string;
};

const BannerInfo: React.FC<BannerInfoProps> = ({ children, className }) => (
  <div className={clsx('banner-info', className)}>{children}</div>
);

export default BannerInfo;
