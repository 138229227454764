import React from 'react';
import { Button, Typography } from '@kiiwi/ui';
import Link from '../../atoms/Link/Link';
import { useTranslation } from '@/src/i18n';
import CARDS_DATA from './cardsData';
import useResponsive from '@/src/hooks/useResponsive';

const HomePageCards = () => {
  const { t } = useTranslation();
  const activeCards = CARDS_DATA.filter(card => card.active === true);
  const { isMobile } = useResponsive();
  return (
    <div className="no-scrollbar relative -mt-16 overflow-x-auto text-center">
      <div
        className={` flex flex-row justify-center gap-4 bg-transparent ${
          isMobile ? 'w-fit px-4' : ''
        }`}
      >
        {activeCards.map(card => (
          <div
            key={card.buttonLabel}
            className="flex w-[211px] min-w-[211px] flex-col gap-4 rounded-xl border border-global-neutral bg-surface-white px-4 py-4 lg:w-[340px] lg:flex-row"
          >
            <div className="self-center">
              <img
                src={`/static/images/home/cards/${card.media}.svg`}
                className="h-24 lg:h-24"
                alt={t(card.content)}
              />
            </div>
            <div className="flex flex-1 flex-col justify-between gap-4 text-center lg:w-[236px] lg:text-start">
              <Typography
                variant="body-1"
                className="text-base font-semibold text-content-primary"
                title={t(card.content)}
              >
                {t(card.content)}
              </Typography>
              <Link href={card.link}>
                <Button
                  data-testid={card.buttonLabel}
                  aria-label={t(card?.ariaLabel)}
                  className="cursor-pointer lg:w-fit"
                  size="md"
                  variant="secondary"
                >
                  {t(card.buttonLabel)}
                </Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default HomePageCards;
