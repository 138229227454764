import React from 'react';
import { useRouter } from 'next/router';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';
import { APP_ROUTES } from '@/src/constants';
import { useTranslation } from '@/src/i18n';

const MobileFakeSearch = () => {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <div onClick={() => router.push(APP_ROUTES.SEARCH.SUGGESTION)}>
      <div className="mt-6 inline-flex h-14 w-full cursor-pointer items-center justify-start gap-4 rounded-full border border-global-neutral bg-white p-0.5">
        <div className="flex h-12 shrink grow basis-0 items-start justify-start gap-2">
          <div className="flex shrink grow basis-0 items-center justify-start gap-3 self-stretch rounded-3xl py-1 pl-4 pr-1">
            <div className="text-slate-500 shrink grow basis-0 text-base font-normal text-content-secondary">
              {t('search-a-practitioner')}
            </div>
          </div>
          <div className="flex h-12 w-12 items-center justify-center rounded-3xl bg-macro-enable">
            <FontAwesomeIcon
              name="magnifying-glass:regular"
              className="size-5 text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFakeSearch;
