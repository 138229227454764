/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';
import { Button, Heading, Typography, cn } from '@kiiwi/ui';
import Link from 'next/link';
import { APP_ROUTES, MAP_PHARMARCIES_URL } from '@/src/constants';
import useResponsive from '@/src/hooks/useResponsive';
import { Trans, useTranslation } from '@/src/i18n';

const cleanAltImg = (text: string) => {
  return text
    .replaceAll(/<[^>]+>/g, ' ')
    .replaceAll('  ', ' ')
    .trim();
};

const HomeTextBlock = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  return (
    <>
      <div
        className={cn(
          'flex flex-col items-center gap-8 px-4 py-8 sm:py-[60px] lg:min-h-[500px] lg:flex-row lg:justify-center lg:px-20 lg:py-8',
        )}
      >
        <div className="w-[230px] lg:w-[600px]">
          <img
            src="/static/images/home/schedule_with_your_healthcare_professionals.svg"
            alt={cleanAltImg(
              t('take_appointment_with_healthcare_professionals'),
            )}
          />
        </div>
        <div className={cn('flex w-full max-w-[600px] flex-col gap-4')}>
          <Heading
            variant={isMobile ? 'title-2' : 'title-1'}
            as="h3"
            className="text-content-primary"
          >
            <Trans
              i18nKey="take_appointment_with_healthcare_professionals"
              components={[<span className="block" key="0" />]}
            />
          </Heading>
          <Typography variant="body-1" className="text-content-primary">
            <Trans
              i18nKey="consult_with_your_practitioners_from_everywhere"
              components={[<span className="block" key="0" />]}
            />
          </Typography>
          <Link href="/medecin-generaliste/75001-PARIS">
            <Button className="w-fit" variant="primary" size="md">
              {t('book_appointment')}
            </Button>
          </Link>
        </div>
      </div>
      <div
        className={cn(
          'flex flex-col items-center gap-8 bg-secondary-hover px-4 py-8 sm:py-[60px] lg:min-h-[500px] lg:flex-row-reverse lg:justify-center lg:px-20 lg:py-8',
        )}
      >
        <div className="w-[180px] lg:w-[600px]">
          <img
            src="/static/images/home/your_teleconsultation_anytime.svg"
            alt={cleanAltImg(
              t('need_a_quick_diagnostic_teleconsult_at_any_time_2'),
            )}
            className="m-auto"
          />
        </div>
        <div className={cn('flex w-full max-w-[600px] flex-col gap-4')}>
          <Heading
            variant={isMobile ? 'title-2' : 'title-1'}
            as="h3"
            className="text-content-primary"
          >
            <Trans
              i18nKey="need_a_quick_diagnostic_teleconsult_at_any_time_2"
              components={[<span className="block" key="0" />]}
            />
          </Heading>
          <Typography variant="body-1" className="text-content-primary">
            <Trans
              i18nKey="teleconsultation_are_refunded_2"
              components={[<span className="block" key="0" />]}
            />
          </Typography>
          <Link href={APP_ROUTES.TLC.NO_RDV_SEARCH}>
            <Button className="w-fit" variant="primary" size="md">
              {t('teleconsult_without_appointment')}
            </Button>
          </Link>
        </div>
      </div>
      <div
        className={cn(
          'flex flex-col items-center gap-8 px-4 py-8 sm:px-4 sm:py-[60px] lg:min-h-[500px] lg:flex-row lg:justify-center lg:px-20 lg:py-8',
        )}
      >
        <div className="w-[180px] lg:w-[600px]">
          <img
            src="/static/images/home/your_medication_details_in_a_few_clicks.svg"
            alt={cleanAltImg(
              t('all_the_details_about_your_drugs_in_just_a_few_clicks'),
            )}
            className="m-auto"
          />
        </div>
        <div className={cn('flex w-full max-w-[600px] flex-col gap-4')}>
          <Heading
            variant={isMobile ? 'title-2' : 'title-1'}
            as="h3"
            className="text-content-primary"
          >
            <Trans
              i18nKey="all_the_details_about_your_drugs_in_just_a_few_clicks"
              components={[<span className="block" key="0" />]}
            />
          </Heading>
          <span>
            <Typography variant="body-1" className="text-content-primary">
              <Trans
                i18nKey="find_all_the_essential_information_about_your_drugs"
                components={[<span className="block" key="0" />]}
              />
            </Typography>
          </span>
          <Link href={APP_ROUTES.MEDICAMENTS.BASE_URL}>
            <Button className="w-fit" variant="primary" size="md">
              {t('search_for_a_drug')}
            </Button>
          </Link>
        </div>
      </div>
      <div
        className={cn(
          'flex flex-col items-center gap-8 bg-secondary-hover px-4 py-8 sm:px-4 sm:py-[60px] lg:min-h-[500px] lg:flex-row-reverse lg:justify-center lg:px-20 lg:py-8',
        )}
      >
        <div className="absolute right-0 hidden lg:block">
          <img
            src="/static/images/home/pharmacy_teleconsultation_desktop.svg"
            alt={cleanAltImg(t('teleconsult_from_pharmacies'))}
          />
        </div>

        <div className="w-[230px] lg:w-[600px]">
          <img
            src="/static/images/home/pharmacy_teleconsultation.svg"
            alt={cleanAltImg(t('teleconsult_from_pharmacies'))}
            className="m-auto lg:hidden"
          />
        </div>

        <div className={cn('flex w-full max-w-[600px] flex-col gap-4')}>
          <Heading
            variant={isMobile ? 'title-2' : 'title-1'}
            as="h3"
            className="text-content-primary"
          >
            <Trans
              i18nKey="teleconsult_from_pharmacies"
              components={[<span className="block" key="0" />]}
            />
          </Heading>
          <span>
            <Typography variant="body-1" className="text-content-primary">
              <Trans
                i18nKey="teleconsultation_are_refunded_2"
                components={[<span className="block" key="0" />]}
              />
            </Typography>
          </span>
          <Link href={MAP_PHARMARCIES_URL}>
            <Button className="w-fit" variant="primary" size="md">
              {t('find_a_pharmacy')}
            </Button>
          </Link>
        </div>
      </div>
      <div
        className={cn(
          'flex flex-col items-center gap-8 px-4 py-8 sm:px-4 sm:py-[60px] lg:min-h-[500px] lg:flex-row lg:justify-center lg:px-20 lg:py-8',
        )}
      >
        <div className="w-[150px] lg:w-[600px]">
          <img
            src="/static/images/home/your_health_documents_always_with_you.svg"
            alt={cleanAltImg(t('your_documents_always_with_you'))}
            className="m-auto"
          />
        </div>
        <div className={cn('flex w-full max-w-[600px] flex-col gap-4')}>
          <Heading
            variant={isMobile ? 'title-2' : 'title-1'}
            as="h3"
            className="text-content-primary"
          >
            <Trans
              i18nKey="your_documents_always_with_you"
              components={[<span className="block" key="0" />]}
            />
          </Heading>
          <span>
            <Typography variant="body-1" className="text-content-primary">
              <Trans
                i18nKey="store_your_medical_documents_in_secured_place"
                components={[<span className="block" key="0" />]}
              />
            </Typography>
          </span>
          <Link href={APP_ROUTES.DOCUMENTS}>
            <Button className="w-fit" variant="primary" size="md">
              {t('create_your_medical_folder')}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default HomeTextBlock;
